import { useState } from "react";
import classes from "./PortfolioElement.module.scss";
import { Col, Collapse, Container, Fade, Ratio, Row } from "react-bootstrap";
import PortfolioCard from "../PortfolioCard/PortfolioCard";
import arrow from "../../../Images/arrow.svg";

export function PortfolioElement({ img, title, description, children, open, setOpen, id }) {
  return (
    <>
      <div
        className={classes.card}
        style={{ backgroundImage: `url(${img})` }}
        onClick={() => {
          setOpen(!open);
        }}
        aria-controls={"block1"}
        aria-expanded={open}
        id={id}
      >
        <div className={classes.shader}>
          <Container className="h-100">
            <Row className="h-100 d-flex justify-content-between align-items-end  ">
              <Col md={9}>
                <div className={classes.content}>
                  <h2>{title}</h2>
                  <p>{description}</p>
                </div>
              </Col>
              <Col md={2}>
                <div className={classes.arrow}>
                  <img src={arrow} alt="" style={{ transform: open ? "rotate(180deg)" : "none" }} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Collapse in={open}>
        <div id="block1" className={classes.cardContent}>
          <Row md={3} sm={2} className="w-100 m-0">
            {children}
          </Row>
        </div>
      </Collapse>
    </>
  );
}
