import classes from "./Main.module.scss";

import LargeLogo from "../../Images/Main/LargeLogo.js";
import { Container } from "react-bootstrap";

function Main() {
  const achievementsMobile = [
    { count: 47, title: "Реализованных проектов" },
    { count: 5, title: "Регионов страны" },
    { count: 24, title: "Довольных заказчиков" },
  ];

  // if (window.innerWidth >= 540) {
  return (
    <>
      <div className={classes.root}>
        <div className={classes.shader}>
          <Container>
            <div className={classes.nameContainer}>
              <div>
                <span className={classes.firstLetter}>Б</span>
                <span>ЮРО</span>
              </div>
              <div>
                <span className={classes.firstLetter}>А</span>
                <span>РХИТЕКТУРЫ</span>
              </div>
              <div>
                <span className={classes.firstLetter}>Р</span>
                <span>АЗВИТИЯ И</span>
              </div>
              <div>
                <span className={classes.firstLetter}>Д</span>
                <span>ИЗАЙНА</span>
              </div>
              <div>
                <span className={classes.firstLetter}>О</span>
                <span>БЪЕКТОВ</span>
              </div>
            </div>
            <div className={classes.subnameContainer}>
              <div className={classes.bannerSlogan}>Жизнь проекта от идеи до реализации</div>
              {/* <div className={classes.bannerSocial}>
                  <img src={email} alt="email" />
                  <img src={whatsup} alt="whatsup" />
                  <img src={instagram} alt="instagram" />
                </div> */}
            </div>
          </Container>
        </div>
      </div>
      <div className={classes.mainInformation}>
        <Container>
          <div className={classes.info1}>
            <h3>О БюРО</h3>
            <p>
              Мы создаем уникальную среду обитания с современным взглядом на архитектуру и дизайн. Рамки остаются только на чертежах, в нашей работе
              нет ограничений. Портфолио архитектурного бюро “БАРДО” состоит из проектов различного содержания: нам интересны любые проекты, начиная
              от генерального плана города и заканчивая малыми архитектурными формами.
            </p>
          </div>
          <div className={classes.info2}>
            <p>
              Наше бюро готово подключиться к работе на любом этапе жизненного цикла проекта. Мы работаем в 5 направлениях: архитектурный консалтинг,
              проектирование, дизайн, технический заказчик, а также эксплуатация и автоматизация.
            </p>
          </div>
        </Container>
        <div className={classes.info3}>
          <Container>
            <div className={classes.info3Content}>
              <div className={classes.largeLogo}>
                <LargeLogo />
              </div>
              <div className={classes.info3Text}>
                <h3>философия бюро</h3>
                <p>
                  Каждый сотрудник архитектруного бюро “БАРДО” изо дня в день работает не только с красивыми картинками визуализаций и
                  дизайн-концепций. Большую часть рабочего дня мы проводим над чертежами, а именно над планами. Поэтому и на нашем логотипе Вы можете
                  увидеть план нашего бюро.
                </p>
                <br />
                <p>
                  Вы удивлены и не можете понять, как же живет наш офис в такой странной планировке? Присмотритесь, возможно там запрятались очень
                  важные для нас буквы ;)
                </p>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
  // } else {
  //   return (
  //     <div className={classes.mobileRoot}>
  //       <div className={classes.shader}>
  //         <div className={classes.main}>
  //           <div className={classes.container}>
  //             <div className={classes.mainHeader}>
  //               <div>
  //                 <span className={classes.firstLetter}>Б</span>
  //                 <span>ЮРО</span>
  //               </div>
  //               <div>
  //                 <span className={classes.firstLetter}>А</span>
  //                 <span>РХИТЕКТУРЫ</span>
  //               </div>
  //               <div>
  //                 <span className={classes.firstLetter}>Р</span>
  //                 <span>АЗВИТИЯ И</span>
  //               </div>
  //               <div>
  //                 <span className={classes.firstLetter}>Д</span>
  //                 <span>ИЗАЙНА</span>
  //               </div>
  //               <div>
  //                 <span className={classes.firstLetter}>О</span>
  //                 <span>БЪЕКТОВ</span>
  //               </div>
  //             </div>
  //             <div className={classes.subMain}>
  //               <div className={classes.bannerSlogan}>Жизнь проекта от идеи до реализации</div>
  //             </div>

  //             <div className={classes.achievements}>
  //               {achievementsMobile.map((a, index) => {
  //                 return (
  //                   <div key={index}>
  //                     <span>{a.count}</span>
  //                     <span>{a.title}</span>
  //                   </div>
  //                 );
  //               })}
  //             </div>

  //             <div className={classes.text}>
  //               <p>Архитектурное бюро “Бардо” было основано в 2015 году.</p>
  //               <p>
  //                 За время нашей работы мы воплотили в жизнь большое количество проектов и смогли собрать под своим руководством настоящих
  //                 профессионалов с большим опытом и отличной квалификацией.
  //               </p>
  //             </div>
  //           </div>
  //         </div>

  //         <div className={classes.bureau}>
  //           <div className={classes.bureau__main}>
  //             <h3>БЮРО</h3>
  //           </div>
  //           <div className={classes.container}>
  //             <div className={classes.main__info}>
  //               <p>
  //                 Мы готовы подключиться на любом этапе жизненного цикла проекта, будь то идея для инвестиций, проектирвание <h3>БАРДО</h3>{" "}
  //                 малоэтажного жилого дома на одну семью или диспетчеризация офисного здания более 5-ти этажей. Поэтому наше портфолио разнообразно, а
  //                 наши специалисты обладают обширными знаниями во всех областях наших направлений.
  //               </p>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
}

export default Main;
