import classes from "./Footer.module.scss";
import footerSVG from "../../Images/logoFooter.png";
import { Link, useLocation } from "react-router-dom";
import Button from "../Button/Button";
import emailImg from "../../Images/Main/email.svg";
import whatsupImg from "../../Images/Main/whatsup.svg";
import tgImg from "../../Images/tgLogo.svg";
import { Container } from "react-bootstrap";

function Footer() {
  const { hash } = useLocation();

  if (window.innerWidth >= 540) {
    return (
      <footer className={classes.root}>
        <Container>
          <div className={classes.row}>
            <div className={classes.title}>
              <h4>B’ardeaux</h4>
            </div>
            <div className={classes.services}>
              <Link to="/services">
                <h5>Услуги</h5>
              </Link>

              <Link to="/services">
                <p>Архитектурный консалтинг</p>
              </Link>
              <Link to="/services">
                <p>Комплексное проектирование</p>
              </Link>
              <Link to="/services">
                <p>Комплектация объектов</p>
              </Link>
              <Link to="/services">
                <p>Технический заказчик</p>
              </Link>
              <Link to="/services">
                <p>Сопровождение проектов</p>
              </Link>
            </div>
            <div className={classes.contacts}>
              <h5>Контакты</h5>
              <p>
                <b>Адрес:</b> Москва, ул. Ленинская Слобода, д.19 (БЦ “Омега Плаза”)
              </p>
              <p>
                <b>Телефон:</b> +7 (495) 177-53-45
              </p>
              <p>
                <b>E-mail:</b> info@bardeaux.ru
              </p>
            </div>
            <div className={classes.logo}>
              <img src={footerSVG} alt="BARDO" />
            </div>
            {/* <div className={classes.socials}>
              <img src={emailImg} alt="" />
              <img src={whatsupImg} alt="" />
              <img src={tgImg} alt="" />
            </div> */}
          </div>
        </Container>
      </footer>
    );
  } else {
    return (
      <footer className={classes.mobileRoot}>
        <div className={classes.content}>
          <div className={classes.header}>
            <div className={classes.socials}>
              <img src={emailImg} alt="" />
              <img src={whatsupImg} alt="" />
              <img src={tgImg} alt="" />
            </div>
            <h4>+7 (495) 177-53-45</h4>
            <p>Москва, ул. Ленинская Слобода, д.19 (БЦ “Омега Плаза”)</p>
          </div>
          <div className={classes.form}>
            <input type="text" placeholder="Ваше сообщение"></input>
            <div className={classes.button}>
              <Button title="отправить" />
            </div>
            <p>© 2020 BARDEAUX</p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
