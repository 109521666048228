import servicesBlock1 from "../Images/Services/servicesBlock1.png";
import servicesBlock2 from "../Images/Services/servicesBlock2.png";
import servicesBlock3 from "../Images/Services/servicesBlock3.png";
import servicesBlock4 from "../Images/Services/servicesBlock4.png";
import servicesBlock5 from "../Images/Services/servicesBlock5.png";

export const services = [
  {
    title: "Архитектурный консалтинг",
    text: "Разработка архитектурной концепции, архитектурно-градостроительный облик объекта c максимальными показателями застройки",
    img: servicesBlock1,
    items: [
      {
        title: "Архитектурный консалтинг",
        text: "Разработка архитектурной концепции, архитектурно-градостроительный облик объекта c максимальными показателями застройки",
        img: servicesBlock1,
        link: "architecturalConsulting",
      },
      {
        title: "Архитектурный консалтинг",
        text: "Разработка архитектурной концепции, архитектурно-градостроительный облик объекта c максимальными показателями застройки",
        img: servicesBlock1,
        link: "architecturalConsulting",
      },
      {
        title: "Архитектурный консалтинг",
        text: "Разработка архитектурной концепции, архитектурно-градостроительный облик объекта c максимальными показателями застройки",
        img: servicesBlock1,
        link: "architecturalConsulting",
      },
      {
        title: "Архитектурный консалтинг",
        text: "Разработка архитектурной концепции, архитектурно-градостроительный облик объекта c максимальными показателями застройки",
        img: servicesBlock1,
        link: "architecturalConsulting",
      },
      {
        title: "Архитектурный консалтинг",
        text: "Разработка архитектурной концепции, архитектурно-градостроительный облик объекта c максимальными показателями застройки",
        img: servicesBlock1,
        link: "architecturalConsulting",
      },
      {
        title: "Архитектурный консалтинг",
        text: "Разработка архитектурной концепции, архитектурно-градостроительный облик объекта c максимальными показателями застройки",
        img: servicesBlock1,
        link: "architecturalConsulting",
      },
    ],
  },
  {
    title: "Комплексное проектирование",
    text: "Сбор ИРД, Проектирование, согласования, экспертиза – все в одном месте",
    img: servicesBlock2,
    items: [],
  },
  {
    title: "Комплектация объектов",
    text: "Подбор оборудования для проектов и обеспечение объекта всем необходимым оборудованием, материалами, средствами защиты, инструментами и прочими комплектующими",
    img: servicesBlock3,
    items: [],
  },
  {
    title: "Технический заказчик",
    text: "Передача организационных и контрольных функций профессиональному техническому заказчику снимет проблемы с инвестора/застройщика",
    img: servicesBlock4,
    items: [],
  },
  {
    title: "Сопровождение проектов",
    text: "Предоставление услуг по управлению строительными проектами на всех стадиях жизненного цикла, предоставляя комплексный подход и обеспечивая полный контроль в ходе их реализации",
    img: servicesBlock5,
    items: [],
  },
];
