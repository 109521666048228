import block1 from "../Images/Portfolio/block1.png";
import block2 from "../Images/Portfolio/block2.png";
import block2_1 from "../Images/Portfolio/block2_1.png";

import block3 from "../Images/Portfolio/block3.png";
import block4 from "../Images/Portfolio/block4.png";
import block5 from "../Images/Portfolio/block5.png";

import tassImg from "../Images/Portfolio/tass_quad.png";

import tass1 from "../Images/Portfolio/tass/1.jpg";
import tass2 from "../Images/Portfolio/tass/2.jpg";
import tass3 from "../Images/Portfolio/tass/3.jpg";
import tass4 from "../Images/Portfolio/tass/4.jpg";
import tass5 from "../Images/Portfolio/tass/5.jpg";
import tass6 from "../Images/Portfolio/tass/6.jpg";
import tass7 from "../Images/Portfolio/tass/7.jpg";
import tass8 from "../Images/Portfolio/tass/19.jpg";
import tass9 from "../Images/Portfolio/tass/20.jpg";
import tass10 from "../Images/Portfolio/tass/26.jpg";
import tass11 from "../Images/Portfolio/tass/32.jpg";
import tass12 from "../Images/Portfolio/tass/38.jpg";
import tass13 from "../Images/Portfolio/tass/42.jpg";
import tass14 from "../Images/Portfolio/tass/55.jpg";
import tass15 from "../Images/Portfolio/tass/57.jpg";
import tass16 from "../Images/Portfolio/tass/67.jpg";
import tass17 from "../Images/Portfolio/tass/68.jpg";
import tass18 from "../Images/Portfolio/tass/72.jpg";
import tass19 from "../Images/Portfolio/tass/76.jpg";
import tass20 from "../Images/Portfolio/tass/78.jpg";
import tass21 from "../Images/Portfolio/tass/85.jpg";
import tass22 from "../Images/Portfolio/tass/86.jpg";
import tass23 from "../Images/Portfolio/tass/89.jpg";
import tass24 from "../Images/Portfolio/tass/90.jpg";
import tass25 from "../Images/Portfolio/tass/91.jpg";
import tass26 from "../Images/Portfolio/tass/92.jpg";
import tass27 from "../Images/Portfolio/tass/106.jpg";
import tass28 from "../Images/Portfolio/tass/107.jpg";

import mts1 from "../Images/Portfolio/mts/1.jpg";
import mts2 from "../Images/Portfolio/mts/2.jpg";
import mts3 from "../Images/Portfolio/mts/3.jpg";
import mts4 from "../Images/Portfolio/mts/4.jpg";
import mts5 from "../Images/Portfolio/mts/5.jpg";
import mts6 from "../Images/Portfolio/mts/6.jpg";

import bardo1 from "../Images/Portfolio/bardo/1.jpg";
import bardo2 from "../Images/Portfolio/bardo/2.jpg";
import bardo3 from "../Images/Portfolio/bardo/3.jpg";
import bardo4 from "../Images/Portfolio/bardo/4.jpg";
import bardo5 from "../Images/Portfolio/bardo/5.jpg";

import tverskaya1 from "../Images/Portfolio/tverskaya/1.jpg";
import tverskaya2 from "../Images/Portfolio/tverskaya/2.jpg";
import tverskaya3 from "../Images/Portfolio/tverskaya/3.jpg";
import tverskaya4 from "../Images/Portfolio/tverskaya/4.jpg";
import tverskaya5 from "../Images/Portfolio/tverskaya/5.jpg";
import tverskaya6 from "../Images/Portfolio/tverskaya/6.jpg";
import tverskaya7 from "../Images/Portfolio/tverskaya/7.jpg";
import tverskaya8 from "../Images/Portfolio/tverskaya/8.jpg";
import tverskaya9 from "../Images/Portfolio/tverskaya/9.jpg";

import tassDom1 from "../Images/Portfolio/tassDom/1.jpg";
import tassDom2 from "../Images/Portfolio/tassDom/2.jpg";
import tassDom3 from "../Images/Portfolio/tassDom/3.jpg";
import tassDom4 from "../Images/Portfolio/tassDom/4.jpg";
import tassDom5 from "../Images/Portfolio/tassDom/5.jpg";
import tassDom6 from "../Images/Portfolio/tassDom/6.jpg";

import sochi1 from "../Images/Portfolio/sochi/1.jpg";
import sochi2 from "../Images/Portfolio/sochi/2.jpg";
import sochi3 from "../Images/Portfolio/sochi/3.jpg";
import sochi4 from "../Images/Portfolio/sochi/4.jpg";
import sochi5 from "../Images/Portfolio/sochi/5.jpg";
import sochi6 from "../Images/Portfolio/sochi/6.jpg";
import sochi7 from "../Images/Portfolio/sochi/7.jpg";

export const projects = [
  {
    title: "Административные проекты",
    text: "Разрабатываем проекты любой сложности, от проработки концепции и дизайн-проекта, до прохождения экспертизы и реализации задуманных идей.",
    img: block2,
    items: [
      {
        title: "",
        text: "Разработка проектной и рабочей документации для реализации мероприятий по капитальному ремонту помещений здания ИТАР-ТАСС",
        img: tass19,
        link: "itarTass",
        withoutContent: true,
        data: {
          name: "Многофункциональный медиа-центр ИТАР-ТАСС",
          mainImg: block2,
          sliderImgs: [
            tass1,
            tass2,
            tass3,
            tass3,
            tass4,
            tass5,
            tass6,
            tass7,
            tass8,
            tass9,
            tass10,
            tass11,
            tass12,
            tass13,
            tass14,
            tass15,
            tass16,
            tass17,
            tass18,
            tass19,
            tass20,
            tass21,
            tass22,
            tass23,
            tass24,
            tass26,
            tass27,
            tass28,
          ],
          title: "Заказчик: ФГУП «Информационное телеграфное агентство (ИТАР-ТАСС)»",
          text: [
            "В  соответствии с техническим заданием была разработана концепция проекта «Многофункциональный медиа-центр ИТАР-ТАСС».",
            "Предложены современные технологические решения для удобства посетителей пространства, для трансляции прямых эфиров.",
            "Детально проработаны три разных типа дизайн-проекта под каждое помещение (входная группа, холл, столовая, пресс-центр на 60 человек, конференц зал на 250-300 человек),  сохранив культурный код здания.",
            "Разработана проектно-сметная документация для прохождения экспертизы и согласования бюджета с государственным заказчиком.",
          ],
          img: tass25,
        },
      },
      {
        title: "ИТАР-ТАСС",
        text: "Разработка проектной и рабочей документации для реализации мероприятий по капитальному ремонту помещений здания ИТАР-ТАСС",
        img: tassImg,
        link: "itarTass",
      },
      {
        title: "",
        text: "Разработка проектной и рабочей документации для реализации мероприятий по капитальному ремонту помещений здания ИТАР-ТАСС",
        img: tass8,
        link: "itarTass",
        withoutContent: true,
      },
    ],
  },
  {
    title: "Коммерческие проекты",
    text: "Разработка архитектурной концепции, архитектурно-градостроительный облик объекта c максимальными показателями застройки",
    img: block1,
    items: [
      {
        title: "Офис МТС",
        text: "Коммерческое пространство, разработанное для Московского офиса ПАО «МТС»",
        img: mts1,
        link: "mts",
        data: {
          name: "Коммерческое пространство. Офис МТС",
          mainImg: mts2,
          sliderImgs: [mts1, mts2, mts3, mts4, mts5, mts6],
          title: "",
          text: ["Для данное проекта был разработан проект, согласно фирменному стилю."],
        },
      },
      {
        title: "Ленинская Слобода",
        text: "От идеи до реализации «под ключ»  офисного пространства, г. Москва, БЦ «Омега Плаза»",
        img: bardo2,
        link: "bardo",
        data: {
          name: "Концепция офисного пространства, Ленинская Слобода",
          mainImg: bardo5,
          sliderImgs: [bardo1, bardo2, bardo3, bardo4, bardo5],
          title: "",
          text: [
            "Нашей командой был разработан фирменный стиль компании, концепция и дизайн-проект офисного пространства для привлечения новых клиентов и улучшения условий для сотрудников компании.",
          ],
        },
      },
      {
        title: "Тверская, 8",
        text: "Офисное пространство, г. Москва, ул. Тверская. д.8",
        img: tverskaya1,
        link: "tverskaya",
        data: {
          name: "Офисное пространство, г. Москва, ул. Тверская. д.8",
          mainImg: tverskaya2,
          sliderImgs: [tverskaya1, tverskaya2, tverskaya3, tverskaya4, tverskaya5, tverskaya6, tverskaya7, tverskaya8, tverskaya9],
          title: "",
          text: [
            "Нашей командой был разработан фирменный стиль компании, концепция и дизайн-проект офисного пространства для привлечения новых клиентов и улучшения условий для сотрудников компании.",
          ],
        },
      },
    ],
  },
  {
    title: "Экстерьер",
    text: "Передача организационных и контрольных функций профессиональному техническому заказчику снимет проблемы с инвестора/застройщика",
    img: block4,
    items: [
      {
        title: "",
        text: "Капитальный ремонт фасада здания ИТАР-ТАСС, Тверской бульвар, д. 2",
        img: tassDom1,
        link: "tass",
        withoutContent: true,
        data: {
          name: "Капитальный ремонт фасада здания ИТАР-ТАСС",
          mainImg: tassDom1,
          sliderImgs: [tassDom1, tassDom2, tassDom3, tassDom4, tassDom5, tassDom6],
          title: "Проектирование капитального ремонта фасадов здания от идеи до реализации ",
          text: [
            "Комплекс работ по разработке проектно-сметной документации на капитальный ремонт внутренней сети системы отопления здания, системы отделки и декоративных элементов внешних стен здания и концепции архитектурной подсветки фасада административного здания федерального государственного унитарного предприятия «Информационное телеграфное агентство России (ИТАР-ТАСС)», расположенного по адресу: г. Москва, Тверской бульвар, д. 2",
            "В разработке данного проекта, акцент был сделан на трех задачах:",
            "- Сохранить архитектурный облик здания, повторить оконные конструкции «телевизоры», подобрать натуральный камень, соответствующий  задумке архитектора при строительстве здания",
            "- Проработать возможность улучшения системы кондиционирования. Мы предложили современный вариант использования VRV-систем, которые позволяют убрать внешние блоки с фасада здания, и разместить на кровле здания, что улучшит внешний облик объекта архитектуры.  ",
            "- Разработать концепцию архитектурной подсветки фасада, соответствующей нормативам и стилистике центра города.",
          ],
        },
      },
      {
        title: "ИТАР-ТАСС",
        text: "Капитальный ремонт фасада здания ИТАР-ТАСС, Тверской бульвар, д. 2",
        img: tassDom4,
        link: "tass",
        data: {
          name: "Капитальный ремонт фасада здания ИТАР-ТАСС",
          mainImg: tassDom1,
          sliderImgs: [tassDom1, tassDom2, tassDom3, tassDom4, tassDom5, tassDom6],
          title: "Проектирование капитального ремонта фасадов здания от идеи до реализации ",
          text: [
            "Комплекс работ по разработке проектно-сметной документации на капитальный ремонт внутренней сети системы отопления здания, системы отделки и декоративных элементов внешних стен здания и концепции архитектурной подсветки фасада административного здания федерального государственного унитарного предприятия «Информационное телеграфное агентство России (ИТАР-ТАСС)», расположенного по адресу: г. Москва, Тверской бульвар, д. 2",
            "В разработке данного проекта, акцент был сделан на трех задачах:",
            "- Сохранить архитектурный облик здания, повторить оконные конструкции «телевизоры», подобрать натуральный камень, соответствующий  задумке архитектора при строительстве здания",
            "- Проработать возможность улучшения системы кондиционирования. Мы предложили современный вариант использования VRV-систем, которые позволяют убрать внешние блоки с фасада здания, и разместить на кровле здания, что улучшит внешний облик объекта архитектуры.  ",
            "- Разработать концепцию архитектурной подсветки фасада, соответствующей нормативам и стилистике центра города.",
          ],
        },
      },
      {
        title: "",
        text: "Капитальный ремонт фасада здания ИТАР-ТАСС, Тверской бульвар, д. 2",
        img: tassDom6,
        link: "tass",
        withoutContent: true,
        data: {
          name: "Капитальный ремонт фасада здания ИТАР-ТАСС",
          mainImg: tassDom1,
          sliderImgs: [tassDom1, tassDom2, tassDom3, tassDom4, tassDom5, tassDom6],
          title: "Проектирование капитального ремонта фасадов здания от идеи до реализации ",
          text: [
            "Комплекс работ по разработке проектно-сметной документации на капитальный ремонт внутренней сети системы отопления здания, системы отделки и декоративных элементов внешних стен здания и концепции архитектурной подсветки фасада административного здания федерального государственного унитарного предприятия «Информационное телеграфное агентство России (ИТАР-ТАСС)», расположенного по адресу: г. Москва, Тверской бульвар, д. 2",
            "В разработке данного проекта, акцент был сделан на трех задачах:",
            "- Сохранить архитектурный облик здания, повторить оконные конструкции «телевизоры», подобрать натуральный камень, соответствующий  задумке архитектора при строительстве здания",
            "- Проработать возможность улучшения системы кондиционирования. Мы предложили современный вариант использования VRV-систем, которые позволяют убрать внешние блоки с фасада здания, и разместить на кровле здания, что улучшит внешний облик объекта архитектуры.  ",
            "- Разработать концепцию архитектурной подсветки фасада, соответствующей нормативам и стилистике центра города.",
          ],
        },
      },
    ],
  },
  {
    title: "Благоустройство",
    text: "Подбор оборудования для проектов и обеспечение объекта всем необходимым оборудованием, материалами, средствами защиты, инструментами и прочими комплектующими",
    img: block3,
    items: [
      {
        title: "",
        text: "Благоустройство территории на объекте социального, культурного и бытового назначения, расположенного по адресу: г. Сочи, ул. Общинная",
        img: sochi3,
        link: "sochi",
        withoutContent: true,
        data: {
          name: "Благоустройство территории на объекте социального, культурного и бытового назначения",
          mainImg: sochi1,
          sliderImgs: [sochi1, sochi2, sochi3, sochi4, sochi5, sochi6, sochi7],
          title: "",
          text: [
            "Благоустройство территории - это визитная карточка объекта. ",
            "На небольшой площадке мы смогли расположить максимальное количество разнообразных активностей для детей разных возрастов. Учли все потребности в необходимости спортивной площадки, навеса, игровые площадки для разных групп возрастов, спортивные тренажёры для детей и взрослых. ",
          ],
        },
      },
      {
        title: "Благоустройство Сочи",
        text: "Благоустройство территории на объекте социального, культурного и бытового назначения, расположенного по адресу: г. Сочи, ул. Общинная",
        img: sochi1,
        link: "sochi",
        data: {
          name: "Благоустройство территории на объекте социального, культурного и бытового назначения",
          mainImg: sochi1,
          sliderImgs: [sochi1, sochi2, sochi3, sochi4, sochi5, sochi6, sochi7],
          title: "",
          text: [
            "Благоустройство территории - это визитная карточка объекта. ",
            "На небольшой площадке мы смогли расположить максимальное количество разнообразных активностей для детей разных возрастов. Учли все потребности в необходимости спортивной площадки, навеса, игровые площадки для разных групп возрастов, спортивные тренажёры для детей и взрослых. ",
          ],
        },
      },
      {
        title: "",
        text: "Благоустройство территории на объекте социального, культурного и бытового назначения, расположенного по адресу: г. Сочи, ул. Общинная",
        img: sochi6,
        link: "sochi",
        withoutContent: true,
        data: {
          name: "Благоустройство территории на объекте социального, культурного и бытового назначения",
          mainImg: sochi1,
          sliderImgs: [sochi1, sochi2, sochi3, sochi4, sochi5, sochi6, sochi7],
          title: "",
          text: [
            "Благоустройство территории - это визитная карточка объекта. ",
            "На небольшой площадке мы смогли расположить максимальное количество разнообразных активностей для детей разных возрастов. Учли все потребности в необходимости спортивной площадки, навеса, игровые площадки для разных групп возрастов, спортивные тренажёры для детей и взрослых. ",
          ],
        },
      },
    ],
  },
  // {
  //   title: "Инженерное проектирование",
  //   text: "Предоставление услуг по управлению строительными проектами на всех стадиях жизненного цикла, предоставляя комплексный подход и обеспечивая полный контроль в ходе их реализации",
  //   img: block5,
  //   items: [],
  // },
];
