import { Carousel, Col, Container, Image, Row } from "react-bootstrap";
import classes from "./ProjectPage.module.scss";
import { Link } from "react-router-dom";
import { useEffect } from "react";

function ProjectPage({ data }) {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.header} style={{ backgroundImage: `url(${data?.mainImg})` }}>
        <div className={classes.shader}>
          <Container className="h-100">
            <Row className="h-100 d-flex justify-content-between align-items-center ">
              <Col md={2} className="align-self-start">
                <Link to="/portfolio">
                  <p>&larr; НАЗАД</p>
                </Link>
              </Col>
              <Col md={8} className="align-self-center ">
                <h2>{data?.name}</h2>
              </Col>
              <Col />
            </Row>
          </Container>
        </div>
      </div>
      <Container>
        <div className={classes.slider}>
          <Carousel>
            {data?.sliderImgs.map((slide) => {
              return (
                <Carousel.Item key={slide}>
                  <img src={slide} alt="" className="d-block w-100" />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
        <div className={classes.content}>
          <h2>{data?.title}</h2>
          {data.text.map((text) => {
            return <p key={text}>{text}</p>;
          })}
          <img src={data?.img} alt="" />
        </div>
        <div className={classes.backButton}>
          <Link to="/portfolio">
            <h2>&larr; НАЗАД</h2>
          </Link>
        </div>
      </Container>
    </div>
  );
}

export default ProjectPage;
