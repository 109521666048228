import classes from "./Portfolio.module.scss";
import Button from "../../Components/Button/Button";
import { useEffect, useState } from "react";
import PortfolioCard from "./PortfolioCard/PortfolioCard";
import { PortfolioElement } from "./PortfolioElement/PortfolioElement";
import { Link } from "react-router-dom";
import { projects } from "../../Data/Projects";

function Portfolio() {
  const [displayBlock1, setDisplayBlock1] = useState(false);
  const [displayBlock2, setDisplayBlock2] = useState(false);
  const [displayBlock3, setDisplayBlock3] = useState(false);
  const [displayBlock4, setDisplayBlock4] = useState(false);
  const [displayBlock5, setDisplayBlock5] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  // if (window.innerWidth >= 540) {
  return (
    <div className={classes.root}>
      <div className={classes.shader}>
        <div className={classes.header}>
          <span />
          <h2>КОНЦЕПЦИЯ И ВОПЛОЩЕНИЕ</h2>
          <div className={classes.subText}>
            <p>Инновационные Решения в Архитектуре, Дизайне и Техническом Управлении</p>
          </div>
          <span />
          <div className={classes.button}>
            <a href="#firstElement">
              <Button title="Посмотреть проекты" />
            </a>
          </div>
        </div>
      </div>

      {projects.map((project, index) => {
        return (
          <PortfolioElement
            key={project.title}
            title={project.title}
            description={project.text}
            img={project.img}
            id={index === 0 && "firstElement"}
            open={
              index === 0 ? displayBlock1 : index === 1 ? displayBlock2 : index === 2 ? displayBlock3 : index === 3 ? displayBlock4 : displayBlock5
            }
            setOpen={
              index === 0
                ? setDisplayBlock1
                : index === 1
                ? setDisplayBlock2
                : index === 2
                ? setDisplayBlock3
                : index === 3
                ? setDisplayBlock4
                : setDisplayBlock5
            }
          >
            {project.items.map((item) => {
              return (
                <Link key={item.title} to={item.link}>
                  <PortfolioCard withoutContent={item.withoutContent} img={item.img} title={item.title} description={item.text} />
                </Link>
              );
            })}
          </PortfolioElement>
        );
      })}
    </div>
  );
  // } else {
  //   return (
  //     <>
  //       <div className={classes.mobileRoot}>
  //         <div className={classes.block1}>
  //           <div className={classes.textBackground}>
  //             <p>Проект офиса на 50 рабочих мест</p>
  //           </div>
  //         </div>

  //         <div className={classes.block2}>
  //           <div className={classes.textBackground}>
  //             <p>Проект дома средней этажности</p>
  //           </div>
  //         </div>

  //         <div className={classes.block3}>
  //           <div className={classes.textBackground}>
  //             <p>Дизайн-проект жилой квартиры</p>
  //           </div>
  //         </div>

  //         <div className={classes.block4}>
  //           <div className={classes.textBackground}>
  //             <p>Дизайн-проект рекреационной зоны</p>
  //           </div>
  //         </div>

  //         <div className={classes.block5}>
  //           <div className={classes.textBackground}>
  //             <p>Проект гостиницы на 100 мест</p>
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   );
  // }
}

export default Portfolio;
