import classes from "./NavBar.module.scss";
import mainLogo from "../../Images/mainLogo.svg";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

function NavBar() {
  const [menuClicked, setMenuClicked] = useState(false);

  const setActiveClass = ({ isActive, isPending }) => (isActive ? classes.active : isPending ? "" : classes.pending);

  if (window.innerWidth >= 540) {
    return (
      <header className={classes.root}>
        <Container>
          <Row className="flex-row justify-content-between">
            <Col md={1}>
              <div className={classes.logo}>
                <div className={classes.leftLine}></div>
                <Link to="/">
                  <img src={mainLogo} alt="BARDO" />
                </Link>
                <div className={classes.rightLine}></div>
              </div>
            </Col>
            <Col>
              <nav className={classes.NavItems}>
                <div className={classes.NavItem}>
                  <p>
                    <NavLink to={`/`} className={setActiveClass}>
                      Главная
                    </NavLink>
                  </p>
                </div>
                <div className={classes.NavItem}>
                  <p>
                    <NavLink to={`/about`} className={setActiveClass}>
                      О нас
                    </NavLink>
                  </p>
                </div>
                <div className={classes.NavItem}>
                  <p>
                    <NavLink to={`/portfolio`} className={setActiveClass}>
                      Наши проекты
                    </NavLink>
                  </p>
                </div>
                <div className={classes.NavItem}>
                  <p>
                    <NavLink to={`/services?#main`} className={setActiveClass}>
                      Услуги
                    </NavLink>
                  </p>
                </div>
                <div className={classes.NavItem}>
                  <p>
                    <NavLink to={`/contacts`} className={setActiveClass}>
                      Контакты
                    </NavLink>
                  </p>
                </div>
              </nav>
            </Col>
          </Row>
        </Container>
      </header>
    );
  } else {
    return (
      <header className={classes.rootMobile}>
        <div className={classes.content}>
          <Link to="/">
            <div className={classes.logo}>
              <img src={mainLogo} alt="BARDO" />
            </div>
          </Link>
          <div
            className={classes.menu}
            onClick={() => {
              setMenuClicked(!menuClicked);
            }}
          >
            <span />
            <span />
            <span />
          </div>
          {menuClicked && (
            <div className={classes.menuButtonsBlock}>
              <nav className={classes.NavItems}>
                <div className={classes.NavItem}>
                  <p>
                    <NavLink
                      to={`/`}
                      className={setActiveClass}
                      onClick={() => {
                        setMenuClicked(false);
                      }}
                    >
                      Главная
                    </NavLink>
                  </p>
                </div>
                <div className={classes.NavItem}>
                  <p>
                    <NavLink
                      to={`/portfolio`}
                      className={setActiveClass}
                      onClick={() => {
                        setMenuClicked(false);
                      }}
                    >
                      Портфолио
                    </NavLink>
                  </p>
                </div>
                <div className={classes.NavItem}>
                  <p>
                    <NavLink
                      to={`/services?#main`}
                      className={setActiveClass}
                      onClick={() => {
                        setMenuClicked(false);
                      }}
                    >
                      Услуги
                    </NavLink>
                  </p>
                </div>
                <div className={classes.NavItem}>
                  <p>
                    <NavLink
                      to={`/contacts`}
                      className={setActiveClass}
                      onClick={() => {
                        setMenuClicked(false);
                      }}
                    >
                      Контакты
                    </NavLink>
                  </p>
                </div>
              </nav>
              <span className={classes.redLine}></span>
              <span className={classes.blueLine}></span>
            </div>
          )}
        </div>
      </header>
    );
  }
}

export default NavBar;
