import { useState } from "react";
import classes from "./PortfolioCard.module.scss";
import { Col, Collapse, Fade, Ratio } from "react-bootstrap";

function PortfolioCard({ img, title, description, withoutContent }) {
  const [displayDesc, setDisplayDesc] = useState(false);

  return (
    <Col className="p-0">
      <Ratio
        aspectRatio="1x1"
        onMouseEnter={() => {
          setDisplayDesc(true);
        }}
        onMouseLeave={() => {
          setDisplayDesc(false);
        }}
      >
        {withoutContent ? (
          <div className={classes.card} style={{ backgroundImage: `url(${img})` }}></div>
        ) : (
          <div className={classes.card} style={{ backgroundImage: `url(${img})` }}>
            <div className={classes.header} style={{ height: displayDesc && "100%" }}>
              <h2>{title}</h2>
              {displayDesc && <p>{description}</p>}
            </div>
          </div>
        )}
      </Ratio>
    </Col>
  );
}

export default PortfolioCard;
