import classes from "./Bureau.module.scss";
import acfoLogo from "../../Images/Bureau/logo_acfo.png";
import aboutTeam from "../../Images/Bureau/aboutPersons.png";
import logo1 from "../../Images/Bureau/logos/1.png";
import logo2 from "../../Images/Bureau/logos/2.png";
import logo3 from "../../Images/Bureau/logos/3.png";
import logo4 from "../../Images/Bureau/logos/4.png";
import logo5 from "../../Images/Bureau/logos/5.png";
import logo6 from "../../Images/Bureau/logos/6.png";
import logo7 from "../../Images/Bureau/logos/7.png";
import logo8 from "../../Images/Bureau/logos/8.png";
import mchs_logo from "../../Images/Bureau/logos/mchs_logo.png";
import elvis_logo from "../../Images/Bureau/logos/elvis_logo.png";
import sro_logo from "../../Images/Bureau/logos/sro_logo.png";
import mri_logo from "../../Images/Bureau/logos/mri_logo.png";

import { Col, Container, Ratio, Row } from "react-bootstrap";

function Bureau() {
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Container className="d-flex justify-content-end pt-5 ">
          <div className={classes.header__text}>
            <p>
              Компания B’ardeaux была основана в 2015 году. За время нашей работы мы воплотили в жизнь большое количество проектов и смогли собрать
              под своим руководством настоящих профессионалов с большим опытом и отличной квалификацией
            </p>
          </div>
        </Container>
      </div>
      <div className={classes.subHeader}>
        <Container>
          <div className={classes.subHeader__desc}>
            <p>
              Мы готовы подключиться на любом этапе жизненного цикла проекта, будь то идея для инвестиций, проектирование малоэтажного жилого дома на
              одну семью или диспетчеризация офисного здания более 5-ти этажей. Поэтому наше портфолио разнообразно, а наши специалисты обладают
              обширными знаниями во всех областях наших направлений.
            </p>
          </div>
          <div className={classes.akfo}>
            <Container>
              <Row className="d-flex justify-content-center align-content-center ">
                <Col className="d-flex justify-content-around  align-content-center ">
                  <img className=" " src={mri_logo} alt="logo" />
                </Col>
                <Col className="d-flex justify-content-center align-content-center " >
                  <img className=" " src={sro_logo} alt="logo" />
                </Col>
                <Col className="d-flex justify-content-center align-items-center ">
                  <img className="mx-auto" src={acfoLogo} alt="АКФО" />
                </Col>
              </Row>
              <Row className="d-flex justify-content-center align-content-center ">
                <span>
                  <p>Компания B’ardeaux является членом Ассоциации клининговых и фасилити операторов</p>
                </span>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
      <div className={classes.team}>
        <h2>Наша команда</h2>
        <Container>
          <img src={aboutTeam} alt="Team" />
        </Container>
        <div className={classes.team__underline}></div>
        <Container>
          <div className={classes.team__titles}>
            <p>Ген. директор</p>
            <p>ГИП</p>
            <p>Архитектор</p>
            <p>Дизайнер</p>
            <p>3Д-визуализатор</p>
          </div>
        </Container>
      </div>
      <div className={classes.partners}>
        <h2>наши партнеры</h2>
        <div className={classes.partners__underline} />
        <Container>
          <Row md={3} sm={2}>
            <Col className="d-flex justify-content-center align-content-center">
              <img src={logo3} alt="logo" />
            </Col>
            <Col className="d-flex justify-content-center align-content-center">
              <img src={logo7} alt="logo" />
            </Col>
            <Col className="d-flex justify-content-center align-content-center">
              <img src={logo2} alt="logo" />
            </Col>
            <Col className="d-flex justify-content-center align-content-center">
              <img src={logo1} alt="logo" />
            </Col>
            <Col className="d-flex justify-content-center align-content-center">
              <img src={mchs_logo} alt="logo" />
            </Col>
            <Col className="d-flex justify-content-center align-content-center">
              <img src={elvis_logo} alt="logo" />
            </Col>
          </Row>
          <Row md={3} sm={2} className="my-5">
            <Col className="d-flex justify-content-center align-content-center">
              <img src={logo5} alt="logo" />
            </Col>
            <Col className="d-flex justify-content-center align-content-center">
              <img src={logo4} alt="logo" />
            </Col>
            <Col className="d-flex justify-content-center align-content-center">
              <img src={logo8} alt="logo" />
            </Col>
            <Col className="d-flex justify-content-center align-content-center">
              <img src={logo6} alt="logo" />
            </Col>
            <Col className="d-flex justify-content-center align-content-center">
              <img src={sro_logo} alt="logo" />
            </Col>
            <Col className="d-flex justify-content-center align-content-center">
              <img src={mri_logo} alt="logo" />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Bureau;
