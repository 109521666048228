import React from "react";

function LargeLogo() {
  return (
    <svg style={{zIndex:9999}} xmlns="http://www.w3.org/2000/svg" width="254.156" height="415" fill="none" version="1.1" viewBox="0 0 254.156 415">
      <path
        fill="#910000"
        d="M254.156 138.669h-80.827v-34.915h-15.631c-36.003 0-65.4-26.33-67.196-59.442h82.827V.337H0V415h173.329V153.026h65.219V415h15.608V138.669zM72.608 404.789H15.62v6.064h56.988v2.654H15.609v-11.341h56.999zm-5.059 1.555v2.954h-46.87v-2.954zm90.138-5.702H88.228V153.026h69.47zm0-261.973H72.608v261.973H15.609V14.695H157.71v25.823H87.08v3.794c.778 16.255 8.119 31.674 20.61 43.291 6.495 6.107 14.27 10.96 22.863 14.273 8.592 3.312 17.828 5.016 27.157 5.009zm87.034 119.92h3.24v43.249h-3.24zm7.775 47.904h-12.288v-52.548h2.876v52.538h6.548v-52.538h2.876z"
      ></path>
    </svg>
  );
}

export default LargeLogo;
