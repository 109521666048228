import { Route, Routes, useRoutes } from "react-router-dom";
import Main from "../Pages/Main/Main";
import Bureau from "../Pages/Bureau/Bureau";
import Portfolio from "../Pages/Portfolio/Portfolio";
import Services from "../Pages/Services/Services";
import Contacts from "../Pages/Contacts/Contacts";
import ProjectPage from "../Pages/ProjectPage/ProjectPage";
import { projects } from "../Data/Projects";

export function Router() {
  if (window.innerWidth < 540) {
    return (
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<Main />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contacts" element={<Contacts />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/about" element={<Bureau />} />
      <Route path="/portfolio" element={<Portfolio />}></Route>
      {projects.map((project) => {
        return project.items.map((item) => {
          return <Route key={`/portfolio/${item.text}`} path={`/portfolio/${item.link}`} element={<ProjectPage data={item.data} />} />;
        });
      })}
      <Route path="/portfolio/first" element={<ProjectPage data={projects.test} />} />
      <Route path="/services" element={<Services />} />
      <Route path="/contacts" element={<Contacts />} />
    </Routes>
  );
}
