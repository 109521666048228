import { useState } from "react";
import classes from "../FAQ/FAQ.module.scss";
import AccordionItem from "./AccordionItem";

function FAQ() {
  const faqs = [
    {
      question: "Сколько в среднем стоит дизайн-проект и его реализация?",
      answer:
        "С каждым заказчиком стоимость обсуждается индивидуально и зависит, в первую очередь, от Ваших пожеланий, бюджета, комплектации проекта (под ключ или отдельные виды работ) и конкретных задач.",
    },
    {
      question: "Что такое авторский надзор?",
      answer:
        "Это контроль соответствия архитектурно-планировочных, технических, художественных решений и действий подрядчика решениям, принятым в рабочем проекте и зафиксированным в документации.",
    },
    {
      question: "Основные задачи технического заказчика",
      answer:
        "Основными задачами являются: осуществление комплекса представительских, информационных и контрольно-надзорных функций для проведения предпроектной подготовки, инженерных изысканий, работ по подготовке проектной документации, строительства и капитального ремонта, а также технической документации для сдачи объектов капитального строительства в эксплуатацию.",
    },
  ];

  const [clicked, setClicked] = useState("0");

  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked("0");
    }
    setClicked(index);
  };

  return (
    <div className={classes.root}>
      <h3>
        <b>FAQ</b> | Часто задаваемые вопросы
      </h3>
      <div className={classes.accordion__container}>
        <ul className={classes.accordion}>
          {faqs.map((faq, index) => (
            <AccordionItem key={index} faq={faq} onToggle={() => handleToggle(index)} active={clicked === index} />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default FAQ;
