import { useRef } from "react";
import classes from "./FAQ.module.scss";

const AccordionItem = ({ faq, onToggle, active }) => {
  const { question, answer } = faq;

  let classesAccordion = !active ? [classes.accordion_item] : [classes.accordion_item + " " + classes.active];
  let classesWrapper = !active ? [classes.answer_wrapper] : [classes.answer_wrapper + " " + classes.open];

  const contentEl = useRef();

  return (
    <li className={classesAccordion}>
      <button className={classes.button} onClick={onToggle}>
        <p>{question}</p>
        <span className={classes.control}>
          {active ? (
            <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="Polygon 2" d="M15.8457 12L0.845703 23.9911L0.845703 0.00884169L15.8457 12Z" fill="white" />
            </svg>
          ) : (
            <svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="Polygon 3" d="M12.8453 15L0.854131 -2.25877e-06L24.8364 -1.62172e-07L12.8453 15Z" fill="white" />
            </svg>
          )}
        </span>
      </button>
      <div ref={contentEl} className={classesWrapper} style={active ? { height: contentEl.current.scrollHeight } : { height: "0px" }}>
        <div className={classes.answer}>{answer}</div>
      </div>
    </li>
  );
};

export default AccordionItem;
