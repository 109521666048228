import "./App.css";
import NavBar from "./Components/NavBar/NavBar";
import { Router } from "./Router/Router";
import Footer from "./Components/Footer/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Router />
      <Footer />
    </div>
  );
}

export default App;
