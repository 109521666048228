import classes from "./Button.module.scss";

function Button({ title }) {
  return (
    <div className={classes.root}>
      <p>{title}</p>
    </div>
  );
}

export default Button;
